<template>
    <section class="employee-document-authorize" v-loading="documentDataLoading">
      <div class="inner-navbar">
        <el-row type="flex" align="middle" justify="center">
          <el-col
            :xl="{ span: 7 }"
            :lg="{ span: 8 }"
            :md="{ span: 12 }"
            :sm="{ span: 16 }"
          >
            <div class="text-center mt-2">
              <img  src="@/assets/img/eSignsLogoBlack.svg" alt="Logo" width="220" />
            </div>
            <el-card shadow="never" class="mt-2">
              <h2 class="font-weight-normal blue-2 text-center pt-05">
                Scan The QR
              </h2>
              <div>
                <div class="mt-05" shadow="never">
                    <StreamBarcodeReader v-if="!qrReaderResult" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
                </div>

              </div>
            </el-card>
          </el-col>
        </el-row>
  
        <p class="copyright-text text-grey-sm text-center mt-2">
          All Rights Reserved 2024 © Kodefast
        </p>
      </div>
    </section>
  </template>
  
  <script>
//   import { mapGetters } from "vuex";
  
import WhiteLabelConfig from "../../config/app";

import {StreamBarcodeReader}  from "vue-barcode-reader";
  
  export default {
    name: "EmpCustDocTypePwdVerification",
    components: {StreamBarcodeReader},
    created() {},
    data() {
      return {
        qrLoading: false,
            qrCode: null,
            qrReaderResult:''
      };
    },
    async mounted() {
     
    },
    methods: {

        onDecode(text) {
            this.qrReaderResult=text;
            let data= JSON.parse(text);
            if(data.referenceId)
            {
                // http://localhost:8080/entity/view/65a270ac1898b35ebbd91a6a?key=30652&routeType=subEntity&filter=65c4e96e856bf5438d40502a&dataId=65a63a121898b35ebbde5716&layout=STANDARD&page=1&pageSize=10
                window.location.href=`${WhiteLabelConfig.APP_URL}/entity/view/${data.referenceId}?key=${Math.floor(Math.random() * 100000)}&routeType=subEntity&filter=&dataId=${data.dataId}&layout=STANDARD&page=1&pageSize=10`
            }
        },
        onLoaded() {
            console.log(`Ready to start scanning barcodes`)
        },

    },
    
  };
  </script>
  
  <style lang="scss" scoped>
  .employee-document-authorize {
    position: relative;
  }
  .copyright-text {
    position: absolute;
    bottom: -4em;
    left: 0;
    right: 0;
  }
  .error {
    color: #f754a2;
  }
  </style>

